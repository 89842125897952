// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd/lib/grid';
import { CombinedState } from 'reducers';
import { Job, JobType, JobState, JobStage } from 'cvat-core-wrapper';
import JobCard from './job-card';

function ReviewsContentComponent(): JSX.Element {
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const user = useSelector((state: CombinedState) => state.auth.user.id);
    const showRejectedJobs = useSelector((state: CombinedState) => state.jobs.showRejectedJobs);
    // ! BUG: when a new user is created and logged in instantly he will return null

    console.log('Hello world!');
    console.log(jobs);
    console.log(showRejectedJobs);

    const dimensions = {
        md: 22,
        lg: 18,
        xl: 16,
        xxl: 16,
    };

    // Logic for first job in queue
    let newJobs = jobs.filter((job: Job) => job.assignee != null);
    console.log(newJobs);
    const firstJob = newJobs[0];

    //logic for last job
    let oldJobs = jobs
        .filter((job: Job) => job.type === JobStage.VALIDATION)
    //TODO: Make reviewee role here
    const lastJob = oldJobs[oldJobs.length - 1];

    return (
        <Row justify='center' align='middle'>
            <Col className='cvat-jobs-page-list' {...dimensions}>
                {firstJob && <JobCard job={firstJob} key={firstJob.id} />}
                {/* {lastJob && !firstJob ? */}
                {/*     <p>There are no jobs in the queue for you</p> : */}
                {/*     lastJob && firstJob ? <JobCard job={lastJob} key={lastJob.id} /> : */}
                {/*         firstJob && !lastJob ? <JobCard job={firstJob} key={firstJob.id} /> : <p>There are no jobs</p>} */}
            </Col>
        </Row>
    );
}

export default React.memo(ReviewsContentComponent);
