// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { Job, JobType, JobState, JobStage } from 'cvat-core-wrapper';
import { Col } from 'antd/lib/grid';
import Icon, { StopOutlined, CheckCircleOutlined, SaveTwoTone } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import { getJobsAsync } from 'actions/jobs-actions';
import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import { MainMenuIcon, SaveIcon, UndoIcon, RedoIcon } from 'icons';
import { ActiveControl, ToolsBlockerState } from 'reducers';
import CVATTooltip from 'components/common/cvat-tooltip';
import notification from 'antd/lib/notification';

interface Props {
    saving: boolean;
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    drawShortcut: string;
    switchToolsBlockerShortcut: string;
    toolsBlockerState: ToolsBlockerState;
    activeControl: ActiveControl;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onFinishDraw(): void;
    onSwitchToolsBlockerState(): void;
}

function LeftGroup(props: Props): JSX.Element {
    const {
        saving,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        drawShortcut,
        switchToolsBlockerShortcut,
        activeControl,
        toolsBlockerState,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onFinishDraw,
        onSwitchToolsBlockerState,
    } = props;

    const includesDoneButton = [
        ActiveControl.DRAW_POLYGON,
        ActiveControl.DRAW_POLYLINE,
        ActiveControl.DRAW_POINTS,
        ActiveControl.AI_TOOLS,
        ActiveControl.OPENCV_TOOLS,
    ].includes(activeControl);

    const dispatch = useDispatch();
    const query = useSelector((state: CombinedState) => state.jobs.query);
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const user = useSelector((state: CombinedState) => state.auth.user);
    const showRejectedJobs = useSelector((state: CombinedState) => state.jobs.showRejectedJobs);

    const { tid, jid } = useParams();
    console.log(jid);

    const [firstJob, setFirstJob] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false); // New state variable
    const history = useHistory();

    const saveAndRedirect = async () => {
        if (user.role === 'User') {
            if (!saving) {
                // Construct the filter JSON for annotator.id set to user.id
                const combinedFilterJson = JSON.stringify({
                    "and": [
                        {
                            "in": [
                                {
                                    "var": "state"
                                },
                                ["new", "in progress"]
                            ]
                        },
                        {
                            "in": [
                                {
                                    var: "stage"
                                },
                                ["annotation"],
                            ]
                        },
                        {
                            "or": [
                                {
                                    "==": [
                                        {
                                            "var": "assignee"
                                        },
                                        null
                                    ]
                                },
                                {
                                    "==": [
                                        {
                                            "var": "assignee"
                                        },
                                        user.username // Use the dynamic value from user.username
                                    ]
                                }
                            ]
                        },
                        {
                            "==": [
                                {
                                    "var": "annotator_id"
                                },
                                user.id
                            ]
                        }
                    ]
                });

                // Add the filter to the query
                const annotatorFilteredQuery = {
                    ...query,
                    filter: combinedFilterJson
                };

                // Fetch jobs based on annotator ID
                await dispatch(getJobsAsync(annotatorFilteredQuery));

                // Save current annotation
                onSaveAnnotation();

                // Set the redirect flag
                setRedirect(true);
            }
        }
        else {
            notification.error({
                message: 'Only users are allowed to do save and next',
            });
        }
    };

    React.useEffect(() => {
        if (saving) {
            notification.open({
                message: 'Saving',
                description: 'CVAT is saving your annotations, please wait.',
                icon: <SaveTwoTone twoToneColor="#50C878" />,
            });
        }
    }, [saving]);

    // Redirect when saving is complete
    useEffect(() => {
        if (!saving && redirect) {
            let url;
            console.log(jobs);
            if (user.role == 'User') {
                if (firstJob) {
                    const currentJob = jobs.find((job: Job) => job.id === Number(jid));
                    firstJob.assignee = user;
                    firstJob.state = JobState.IN_PROGRESS;
                    currentJob.stage = JobStage.REVIEW;
                    firstJob.save();
                    currentJob.save();
                    url = `/tasks/${firstJob.taskId}/jobs/${firstJob.id}`;
                } else {
                    const currentJob = jobs.find((job: Job) => job.id === Number(jid));
                    currentJob.stage = JobStage.REVIEW;
                    currentJob.save();
                    url = '/jobs'; // redirect to home if firstJob is null
                }
                history.push(url);
                setRedirect(false); // Reset the flag after redirect
            }
        }

    }, [saving, redirect]);

    useEffect(() => {
        if (jobs && jobs.length > 0) {
            const userFilterJobs = jobs.filter((job: Job) => job.annotators.includes(user.id));
            let newJobs = userFilterJobs
                .filter((job: Job) => job.type === JobType.ANNOTATION)
                .filter((job: Job) => job.state === JobState.NEW);
            //BUG: Here jobState doesn't have a value of NEW rather jobStage have value of new
            console.log('these are new jobs');
            console.log(newJobs);

            if (!showRejectedJobs) {
                newJobs = newJobs.filter((job: Job) => job.assignee === null);
            }

            let newFirstJob;
            if (newJobs.length > 1) {
                // Select a random job when there is more than one job
                const randomIndex = Math.floor(Math.random() * newJobs.length);
                newFirstJob = newJobs[randomIndex];
            } else if (newJobs.length === 1) {
                // Select the only job when there is one job
                newFirstJob = newJobs[0];
            }

            setFirstJob(newFirstJob);

            const currentJob = jobs.find((job: Job) => job.id === Number(jid));
            if (currentJob && user.role == 'User') {
                console.log(currentJob)
                currentJob.assignee = user;
                currentJob.state = JobState.IN_PROGRESS
                currentJob.save();
            }
        }
    }, [jobs]);

    const includesToolsBlockerButton =
        [ActiveControl.OPENCV_TOOLS, ActiveControl.AI_TOOLS].includes(activeControl) && toolsBlockerState.buttonVisible;

    const shouldEnableToolsBlockerOnClick = [ActiveControl.OPENCV_TOOLS].includes(activeControl);

    return (
        <>
            <Col className='cvat-annotation-header-left-group'>
                <Dropdown overlay={<AnnotationMenuContainer />}>
                    <Button type='link' className='cvat-annotation-header-menu-button cvat-annotation-header-button'>
                        <Icon component={MainMenuIcon} />
                        Menu
                    </Button>
                </Dropdown>
                <CVATTooltip overlay={`Undo: ${undoAction} ${undoShortcut}`}>
                    <Button
                        style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-undo-button cvat-annotation-header-button'
                        onClick={onUndoClick}
                    >
                        <Icon component={UndoIcon} />
                        <span>Undo</span>
                    </Button>
                </CVATTooltip>
                <CVATTooltip overlay={`Redo: ${redoAction} ${redoShortcut}`}>
                    <Button
                        style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={onRedoClick}
                    >
                        <Icon component={RedoIcon} />
                        Redo
                    </Button>
                </CVATTooltip>
                {includesDoneButton ? (
                    <CVATTooltip overlay={`Press "${drawShortcut}" to finish`}>
                        <Button
                            type='link'
                            className='cvat-annotation-header-done-button cvat-annotation-header-button'
                            onClick={onFinishDraw}
                        >
                            <CheckCircleOutlined />
                            Done
                        </Button>
                    </CVATTooltip>
                ) : null}
                {includesToolsBlockerButton ? (
                    <CVATTooltip overlay={`Press "${switchToolsBlockerShortcut}" to postpone running the algorithm `}>
                        <Button
                            type='link'
                            className={`cvat-annotation-header-block-tool-button cvat-annotation-header-button ${toolsBlockerState.algorithmsLocked ? 'cvat-button-active' : ''
                                }`}
                            onClick={shouldEnableToolsBlockerOnClick ? onSwitchToolsBlockerState : undefined}
                        >
                            <StopOutlined />
                            Block
                        </Button>
                    </CVATTooltip>
                ) : null}
            </Col>
            {user.role === 'User' ?
                <CVATTooltip overlay={`Save current changes ${saveShortcut}`}>
                    <Button
                        onClick={saving ? undefined : onSaveAnnotation}
                        type='link'
                        className={
                            saving
                                ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button'
                                : 'cvat-annotation-header-save-button cvat-annotation-header-button'
                        }
                    >
                        <Icon component={SaveIcon} />
                        {saving ? 'Saving...' : 'Save'}
                    </Button>
                </CVATTooltip> : null}
            <CVATTooltip>
                {' '}
                <Button
                    onClick={saveAndRedirect}
                    type='link'
                    className={
                        saving
                            ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button'
                            : 'cvat-annotation-header-save-button cvat-annotation-header-button'
                    }
                >
                    <Icon component={SaveIcon} />
                    {saving ? 'Saving and redirecting...' : 'Save and go to next page'}
                </Button>
            </CVATTooltip>

            {/* //TODO fix image collision by checking for assignee. */}
        </>
    );
}

export default React.memo(LeftGroup);
