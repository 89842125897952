// Copyright (C) 2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd/lib/grid';
import { CombinedState } from 'reducers';
import { Job, JobType, JobState, JobStage } from 'cvat-core-wrapper';
import JobCard from './job-card';

function JobsContentComponent(): JSX.Element {
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const user = useSelector((state: CombinedState) => state.auth.user);
    const showRejectedJobs = useSelector((state: CombinedState) => state.jobs.showRejectedJobs);
    console.log(user);
    // ! BUG: when a new user is created and logged in instantly he will return null

    const dimensions = {
        md: 22,
        lg: 18,
        xl: 16,
        xxl: 16,
    };

    console.log('All jobs');
    console.log(jobs);

    // Logic for first job in queue
    let newJobs = jobs
        .filter((job: Job) => job.state === JobState.NEW);
    console.log('New jobs');
    console.log(newJobs);

    if (!showRejectedJobs) {
        newJobs = newJobs.filter((job: Job) => job.assignee == null);
    }

    const firstJob = newJobs[0];

    let oldJobs = jobs
        .filter((job: Job) => job.assignee !== null)
        .filter((job: Job) => job.stage === JobStage.ANNOTATION)
        .filter((job: Job) => job.state === JobState.IN_PROGRESS)
        .filter((job: Job) => job.assignee.id == user.id);

    const lastJob = oldJobs.length > 0 ? oldJobs[oldJobs.length - 1] : null;
    if (!lastJob) {
        if(firstJob){
firstJob.assignee = user;
firstJob.state = JobState.IN_PROGRESS;
firstJob.save();
        }
    }

    // rest of the code remains unchanged...

    // BUG: it's not redirecting to last job when not done save & next
    console.log('Old jobs');
    console.log(oldJobs);
    console.log(lastJob);

    return (
        <Row justify='center' align='middle'>
            <Col className='cvat-jobs-page-list' {...dimensions}>
                {/* {firstJob && <JobCard job={firstJob} key={firstJob.id} />} */}
                {lastJob && !firstJob ?
                    <p>There are no jobs in the queue for you</p> :
                    lastJob && firstJob ? <JobCard job={lastJob} key={lastJob.id} /> :
                        firstJob && !lastJob ? <JobCard job={firstJob} key={firstJob.id} /> : <p>There are no jobs</p>}
            </Col>
        </Row>
    );
}

export default React.memo(JobsContentComponent);
