// Copyright (C) 2019-2022 Intel Corporation
// Copyright (C) 2022-2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { Form, Input, Select, Button, Row, Col, Typography } from 'antd';
import notification from 'antd/lib/notification';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import Space from 'antd/lib/space';

import { getCore, Task } from 'cvat-core-wrapper';
import { getReposData, syncRepos, changeRepo } from 'utils/git-utils';
import MdGuideControl from 'components/md-guide/md-guide-control';
import Preview from 'components/common/preview';
import { cancelInferenceAsync } from 'actions/models-actions';
import { CombinedState, ActiveInference } from 'reducers';

interface OwnProps {
    task: Task;
    onUpdateTask: (task: Task) => Promise<void>;
}

interface StateToProps {
    activeInference: ActiveInference | null;
    installedGit: boolean;
    projectSubsets: string[];
    dumpers: any[];
    user: any;
}

interface DispatchToProps {
    cancelAutoAnnotation(): void;
}

function mapStateToProps(state: CombinedState, own: OwnProps): StateToProps & OwnProps {
    const { list } = state.plugins;
    const [taskProject] = state.projects.current.filter((project) => project.id === own.task.projectId);

    return {
        ...own,
        dumpers: state.formats.annotationFormats.dumpers,
        user: state.auth.user,
        installedGit: list.GIT_INTEGRATION,
        activeInference: state.models.inferences[own.task.id] || null,
        projectSubsets: taskProject ?
            ([
                ...new Set(taskProject.subsets),
            ] as string[]) :
            [],
    };
}

function mapDispatchToProps(dispatch: any, own: OwnProps): DispatchToProps {
    return {
        cancelAutoAnnotation(): void {
            dispatch(cancelInferenceAsync(own.task.id));
        },
    };
}

const core = getCore();

interface State {
    name: string;
    projectLabel: string;
    subset: string;
    repository: string;
    repositoryStatus: string;
    format: string;
    lfs: boolean;
    updatingRepository: boolean;
}

type Props = DispatchToProps & StateToProps & OwnProps;

class DetailsComponentSupervisor extends React.PureComponent<Props, State> {
    private mounted: boolean;
    constructor(props: Props) {
        super(props);
        const { task: taskInstance } = props;
        this.mounted = false;
        this.state = {
            name: taskInstance.name,
            ProjectSubsetField: taskInstance.projectLabel,
            subset: taskInstance.subset,
            repository: '',
            format: '',
            repositoryStatus: '',
            lfs: false,
            updatingRepository: false,
        };
    }

    public componentDidMount(): void {
        const { task: taskInstance } = this.props;
        this.mounted = true;

        getReposData(taskInstance.id)
            .then((data): void => {
                if (data !== null && this.mounted) {
                    if (data.status.error) {
                        notification.error({
                            message: 'Could not receive repository status',
                            description: data.status.error,
                        });
                    } else {
                        this.setState({
                            repositoryStatus: data.status.value,
                        });
                    }

                    this.setState({
                        repository: data.url,
                        format: data.format,
                        lfs: !!data.lfs,
                    });
                }
            })
            .catch((error): void => {
                if (this.mounted) {
                    notification.error({
                        message: 'Could not receive repository status',
                        description: error.toString(),
                    });
                }
            });
    }

    public componentDidUpdate(prevProps: Props): void {
        const { task: taskInstance } = this.props;

        if (prevProps !== this.props) {
            this.setState({
                name: taskInstance.name,
            });
        }
    }

    public componentWillUnmount(): void {
        this.mounted = false;
    }

    private renderTaskName(): JSX.Element {
        const { name } = this.state;
        const { task: taskInstance, onUpdateTask } = this.props;

        return (
            <Title level={4}>
                <Text
                    editable={{
                        onChange: (value: string): void => {
                            this.setState({
                                name: value,
                            });

                            taskInstance.name = value;
                            onUpdateTask(taskInstance);
                        },
                    }}
                    className='cvat-text-color'
                >
                    {name}
                </Text>
            </Title>
        );
    }

    private renderDescription(): JSX.Element {
        const { task: taskInstance, onUpdateTask } = this.props;
        const owner = taskInstance.owner ? taskInstance.owner.username : null;
        const created = moment(taskInstance.createdDate).format('MMMM Do YYYY');

        const onFinish = (values) => {
            console.log('Received values from form: ', values);
        };

        const checkSamples = (_, value) => {
            if (value.number > 0) {
                return Promise.resolve();
            }
            return Promise.reject(new Error('Value must be greater than zero!'));
        };

        return (
            <Row className='cvat-task-details-user-block' justify='space-between' align='middle'>
                <Col span={12}>
                    {owner && (
                        <Typography.Text type='secondary'>{`Task #${taskInstance.id} Created by ${owner} on ${created}`}</Typography.Text>
                    )}
                </Col>
            </Row>
        );
    }


    public render(): JSX.Element {
        const {
            task: taskInstance,
        } = this.props;


        return (
            <div className='cvat-task-details'>
                <Row justify='start' align='middle'>
                    <Col className='cvat-task-details-task-name'>{this.renderTaskName()}</Col>
                </Row>
                <Row justify='space-between' align='top'>
                    <Col md={8} lg={7} xl={7} xxl={6}>
                        <Row justify='start' align='middle'>
                            <Col span={24}>
                                <Preview
                                    task={taskInstance}
                                    loadingClassName='cvat-task-item-loading-preview'
                                    emptyPreviewClassName='cvat-task-item-empty-preview'
                                    previewClassName='cvat-task-item-preview'
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={16} lg={17} xl={17} xxl={18}>
                        {this.renderDescription()}
                        {taskInstance.projectId === null && <MdGuideControl instanceType='task' id={taskInstance.id} />}
                        <Row justify='space-between' align='middle'>
                            <Col span={12}>
                                <Space>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailsComponentSupervisor);
