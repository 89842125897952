// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { Job, JobType, JobState, JobStage } from 'cvat-core-wrapper';
import { Col } from 'antd/lib/grid';
import Icon, { StopOutlined, CheckCircleOutlined, LoadingOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import Modal from 'antd/lib/modal';
import Button from 'antd/lib/button';
import Text from 'antd/lib/typography/Text';
import Dropdown from 'antd/lib/dropdown';
import { getJobsAsync } from 'actions/jobs-actions';
import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import { MainMenuIcon, SaveIcon, UndoIcon, RedoIcon } from 'icons';
import { ActiveControl, ToolsBlockerState } from 'reducers';
import { updateUserRoleAsync } from 'actions/auth-actions';
import CVATTooltip from 'components/common/cvat-tooltip';

interface Props {
    saving: boolean;
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    drawShortcut: string;
    switchToolsBlockerShortcut: string;
    toolsBlockerState: ToolsBlockerState;
    activeControl: ActiveControl;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onFinishDraw(): void;
    onSwitchToolsBlockerState(): void;
}

function LeftGroupSupervisor(props: Props): JSX.Element {
    const {
        saving,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        drawShortcut,
        switchToolsBlockerShortcut,
        activeControl,
        toolsBlockerState,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onFinishDraw,
        onSwitchToolsBlockerState,
    } = props;

    const includesDoneButton = [
        ActiveControl.DRAW_POLYGON,
        ActiveControl.DRAW_POLYLINE,
        ActiveControl.DRAW_POINTS,
        ActiveControl.AI_TOOLS,
        ActiveControl.OPENCV_TOOLS,
    ].includes(activeControl);

    const dispatch = useDispatch();
    const query = useSelector((state: CombinedState) => state.jobs.query);
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const user = useSelector((state: CombinedState) => state.auth.user);
    const storedGivenCount = parseInt(localStorage.getItem('givenCount'), 10) || 0;

    const { tid, jid } = useParams();
    console.log(jid);

    const [firstJob, setFirstJob] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false);
    const history = useHistory();


    const saveAndRedirect = async () => {
        if (!saving) {
            // Fetch new jobs
            await dispatch(getJobsAsync({ ...query }));
            // Save current annotation
            onSaveAnnotation();
            // Set the redirect flag
            setRedirect(true);
        }
    };

    // Redirect when saving is complete
    useEffect(() => {
        if (!saving && redirect) {
            if (storedGivenCount > 1) {
                console.log('I am in if block')
                // Subtract 1 from the storedGivenCount
                localStorage.setItem('givenCount', (storedGivenCount - 1).toString());

                // Logic for if block
                if (firstJob) {
                    const url = `/tasks/${firstJob.taskId}/jobs/${firstJob.id}`;
                    history.push(url);
                } else {
                    const url = `/supervise/tasks/${firstJob.taskId}`; // Redirect to home if storedGivenCount < 1
                    history.push(url);
                }
            } else {
                if (firstJob) {
                    console.log('I am in else block')
                    // Logic for else block
                    const url = `/supervise/tasks/${firstJob.taskId}`; // Redirect to home if storedGivenCount < 1
                    history.push(url);
                } else {
                    const url = `/supervise`; // Redirect to home if storedGivenCount < 1
                    history.push(url);
                }
            }
            setRedirect(false); // Reset the flag after redirect
        }
    }, [saving, redirect]);


    useEffect(() => {
        if (jobs && jobs.length > 0) {
            console.log("All job IDs:", jobs.map((job: Job) => job.id));
            const afterFirstFilter = jobs.filter((job: Job) => job.id !== Number(jid));
            console.log("After first filter (excluding jid):", afterFirstFilter.map((job: Job) => job.id));
            const userFilterJobs = jobs
                .filter((job: Job) => job.id !== Number(jid))
                .filter((job: Job) => job.supervisors.includes(user.id))
                .filter((job: Job) => job.taskId == tid)
            console.log(tid);

            let newFirstJob;
            console.log(userFilterJobs);
            if (userFilterJobs.length > 1) {
                // Select a random job when there is more than one job
                const randomIndex = Math.floor(Math.random() * userFilterJobs.length);
                newFirstJob = userFilterJobs[randomIndex];
            } else if (userFilterJobs.length === 1) {
                // Select the only job when there is one job
                newFirstJob = userFilterJobs[0];
            } else {
                newFirstJob = null
            }

            setFirstJob(newFirstJob);
        }
    }, [jobs]);

    const includesToolsBlockerButton =
        [ActiveControl.OPENCV_TOOLS, ActiveControl.AI_TOOLS].includes(activeControl) && toolsBlockerState.buttonVisible;

    const shouldEnableToolsBlockerOnClick = [ActiveControl.OPENCV_TOOLS].includes(activeControl);

    return (
        <>
            <Modal
                className='cvat-saving-job-modal'
                title='Saving changes on the server'
                visible={saving}
                footer={[]}
                closable={false}
            >
                <Text>CVAT is saving your annotations, please wait </Text>
                <LoadingOutlined />
            </Modal>
            <Col className='cvat-annotation-header-left-group'>
                <Dropdown overlay={<AnnotationMenuContainer />}>
                    <Button type='link' className='cvat-annotation-header-menu-button cvat-annotation-header-button'>
                        <Icon component={MainMenuIcon} />
                        Menu
                    </Button>
                </Dropdown>
                <CVATTooltip overlay={`Undo: ${undoAction} ${undoShortcut}`}>
                    <Button
                        style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-undo-button cvat-annotation-header-button'
                        onClick={onUndoClick}
                    >
                        <Icon component={UndoIcon} />
                        <span>Undo</span>
                    </Button>
                </CVATTooltip>
                <CVATTooltip overlay={`Redo: ${redoAction} ${redoShortcut} `}>
                    <Button
                        style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={onRedoClick}
                    >
                        <Icon component={RedoIcon} />
                        Redo
                    </Button>

                </CVATTooltip>
                {includesDoneButton ? (
                    <CVATTooltip overlay={`Press "${drawShortcut}" to finish`}>
                        <Button
                            type='link'
                            className='cvat-annotation-header-done-button cvat-annotation-header-button'
                            onClick={onFinishDraw}
                        >
                            <CheckCircleOutlined />
                            Done
                        </Button>
                    </CVATTooltip>
                ) : null}
                {includesToolsBlockerButton ? (
                    <CVATTooltip overlay={`Press "${switchToolsBlockerShortcut}" to postpone running the algorithm `}>
                        <Button
                            type='link'
                            className={`cvat - annotation - header - block - tool - button cvat - annotation - header - button ${toolsBlockerState.algorithmsLocked ? 'cvat-button-active' : ''
                                } `}
                            onClick={shouldEnableToolsBlockerOnClick ? onSwitchToolsBlockerState : undefined}
                        >
                            <StopOutlined />
                            Block
                        </Button>
                    </CVATTooltip>
                ) : null}
            </Col>
            <CVATTooltip overlay={`Save current changes ${saveShortcut} `}>
                {/* <Button */}
                {/*     onClick={saving ? undefined : onSaveAnnotation} */}
                {/*     type='link' */}
                {/*     className={ */}
                {/*         saving */}
                {/*             ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button' */}
                {/*             : 'cvat-annotation-header-save-button cvat-annotation-header-button' */}
                {/*     } */}
                {/* > */}
                {/*     <Icon component={SaveIcon} /> */}
                {/*     {saving ? 'Saving...' : 'Save'} */}
                {/* </Button> */}

                <Button
                    onClick={saveAndRedirect}
                    type='link'
                    className={
                        saving
                            ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button'
                            : 'cvat-annotation-header-save-button cvat-annotation-header-button'
                    }
                >
                    <Icon component={SaveIcon} />
                    {saving ? 'Saving and redirecting...' : 'Save and next'}
                </Button>
            </CVATTooltip>
            {/* //TODO fix image collision by checking for assignee. */}
        </>
    );
}

export default React.memo(LeftGroupSupervisor);
