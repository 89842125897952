import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { getCore } from 'cvat-core-wrapper';

const { Option } = Select;
const core = getCore();

interface Props {
    value: number[] | null; // Changed from Annotator[] to number[]
    className?: string;
    requiredRole?: string;
    onChange: (userIds: number[] | null) => void;
    disabled?: boolean;
}

const searchUsers = debounce(
    (searchValue: string, setUsers: (users: any[]) => void): void => {
        core.users
            .get({
                search: searchValue,
                limit: 10,
                is_active: true,
            })
            .then((result: any[]) => {
                if (result) {
                    setUsers(result);
                }
            });
    },
    250,
    {
        maxWait: 750,
    },
);

export default function AnnotatorsSelector({ disabled = false, ...props }: Props): JSX.Element {
    const { value, className, onChange } = props;
    const [users, setUsers] = useState<any[]>([]);

    useEffect(() => {
        core.users.get({ limit: 10, is_active: true }).then((result: any[]) => {
            const filteredUsers = props.requiredRole
                ? result.filter((user) => user.role === props.requiredRole)
                : result;
            setUsers(filteredUsers);
        });
    }, [props.requiredRole]);

    const handleChange = (selectedUserIds: number[]): void => {
        onChange(selectedUserIds);  // Directly send the selected IDs
    };

    const handleSearch = (searchValue: string): void => {
        searchUsers(searchValue, setUsers);
    };

    const combinedClassName = className ? `${className} cvat-user-search-field` : 'cvat-user-search-field';

    return (
        <Select
            mode='multiple'
            value={value} // This should be an array of user IDs directly
            placeholder='Select users'
            onSearch={handleSearch}
            onChange={handleChange}
            className={combinedClassName}
            filterOption={false} // to allow searching users
            showSearch
            style={{ width: '100%' }}
            disabled={disabled}
        >
            {users.map((user) => (
                <Option key={user.id} value={user.id}>
                    {user.username}
                </Option>
            ))}
        </Select>
    );
}


