import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCore } from 'cvat-core-wrapper';
import { updateUserRoleAsync } from 'actions/auth-actions';
import { Input, Button, Space, Table, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
const core = getCore();

function ManageContentComponent(): JSX.Element {
    const dispatch = useDispatch();
    const [users, setUsers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    useEffect(() => {
        core.users.get({ is_active: true }).then((result: any[]) => {
            if (result) {
                setUsers(result);
            }
            setLoading(false);  // Turn off loading once data is fetched.
        });
    }, []);

    const roleOptions = [
        { value: 'Admin', label: 'Admin' },
        { value: 'User', label: 'User' },
        { value: 'Reviewer', label: 'Reviewer' },
        { value: 'Supervisor', label: 'Supervisor' },
        { value: 'Manager', label: 'Manager' },
    ];

    const handleRoleChange = async (role: string, userId: number) => {
        console.log(`Role for user ${userId} changed to ${role}`);
        await dispatch(updateUserRoleAsync(userId, role));
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
            <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <Space>
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button
                    onClick={() => handleReset(clearFilters)}
                    size="small"
                    style={{ width: 90 }}
                >
                    Reset
                </Button>
            </Space>
        </div>
    ),
    filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
        record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
    },
    render: (text) =>
        searchedColumn === dataIndex ? (
            <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text ? text.toString() : ''}
            />
        ) : (
            text
        ),
});

    const columns = [
        {
            title: 'Name',
            dataIndex: 'username',
            key: 'username',
            ...getColumnSearchProps('username'),
            // This will allow the name column to take up the remaining space
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '180px', // Adjust this to control the width of the 'Role' column
            render: (text: string, record: any) => (
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '10%' }}>
                    <Select
                        defaultValue={record.role}
                        style={{ width: 120 }}
                        onChange={(value) => handleRoleChange(value, record.id)}
                        options={roleOptions}
                    />
                </div>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={users}
            loading={loading}
            pagination={{ pageSize: 10 }}
        />
    );
}

export default React.memo(ManageContentComponent);
