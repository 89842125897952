// Copyright (C) 2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { useHistory } from 'react-router';
import Button from 'antd/lib/button';

interface Props {
    job: any;
    isAnnotationPage?: boolean;
    isLastElementButton?: boolean;
    isLastJob?: boolean;
}

JobCardComponent.defaultProps = {
    isAnnotationPage: false, // set default value
    isLastElementButton: false,
    isLastJob: false, // set default value
};

function JobCardComponent(props: Props): JSX.Element {
    const { job, isAnnotationPage, isLastJob, isLastElementButton } = props;
    const history = useHistory();
    const onClick = (event: React.MouseEvent): void => {
        const url = `/tasks/${job.taskId}/jobs/${job.id}`;
        if (event.ctrlKey) {
            window.open(url, '_blank', 'noopener noreferrer');
        } else {
            history.push(url);
        }
    };

    let buttonText = isAnnotationPage ? 'Next' : isLastElementButton ? 'Last saved image' : 'Start';
    // if isAnnotationPage then Next otherwise if isLastElementButton then last saved image else start

    const buttonType = isLastJob ? 'secondary' : 'primary';

    return (
        <Button className='cvat-submit-open-project-button' type={buttonType} onClick={onClick}>
            {buttonText}
        </Button>
    );
}

export default React.memo(JobCardComponent);
