
// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { CombinedState } from 'reducers';
import { Job, JobState, JobStage } from 'cvat-core-wrapper';
import { Col } from 'antd/lib/grid';
import Icon, { StopOutlined, CheckCircleOutlined, CheckCircleTwoTone, CloseCircleTwoTone, SaveTwoTone } from '@ant-design/icons';
import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import { getJobsAsync } from 'actions/jobs-actions';
import AnnotationMenuContainer from 'containers/annotation-page/top-bar/annotation-menu';
import { MainMenuIcon, SaveIcon, UndoIcon, RedoIcon } from 'icons';
import { ActiveControl, ToolsBlockerState } from 'reducers';
import notification from 'antd/lib/notification';
import CVATTooltip from 'components/common/cvat-tooltip';

interface Props {
    saving: boolean;
    undoAction?: string;
    redoAction?: string;
    saveShortcut: string;
    undoShortcut: string;
    redoShortcut: string;
    drawShortcut: string;
    switchToolsBlockerShortcut: string;
    toolsBlockerState: ToolsBlockerState;
    activeControl: ActiveControl;
    onSaveAnnotation(): void;
    onUndoClick(): void;
    onRedoClick(): void;
    onFinishDraw(): void;
    onSwitchToolsBlockerState(): void;
}

function LeftGroupReviewer(props: Props): JSX.Element {
    const {
        saving,
        undoAction,
        redoAction,
        saveShortcut,
        undoShortcut,
        redoShortcut,
        drawShortcut,
        switchToolsBlockerShortcut,
        activeControl,
        toolsBlockerState,
        onSaveAnnotation,
        onUndoClick,
        onRedoClick,
        onFinishDraw,
        onSwitchToolsBlockerState,
    } = props;

    const includesDoneButton = [
        ActiveControl.DRAW_POLYGON,
        ActiveControl.DRAW_POLYLINE,
        ActiveControl.DRAW_POINTS,
        ActiveControl.AI_TOOLS,
        ActiveControl.OPENCV_TOOLS,
    ].includes(activeControl);

    const dispatch = useDispatch();
    const query = useSelector((state: CombinedState) => state.jobs.query);
    const jobs = useSelector((state: CombinedState) => state.jobs.current);
    const user = useSelector((state: CombinedState) => state.auth.user);

    const { tid, jid } = useParams();
    console.log(jid);

    const [firstJob, setFirstJob] = React.useState(null);
    const [redirect, setRedirect] = React.useState(false); // New state variable
    const [userRole, setUserRole] = React.useState('testing');
    const history = useHistory();

    const combinedFilterJson = JSON.stringify({
        and: [
            {
                "==": [
                    {
                        var: "stage"
                    },
                    "review"
                ]
            },
            {
                "==": [
                    {
                        var: "reviewer_id"
                    },
                    user.id
                ]
            }
        ]
    });

    // Add the filter to the query
    const reviewerFilteredQuery = {
        ...query,
        filter: combinedFilterJson
    };


    React.useEffect(() => {
        if (saving) {
            notification.open({
                message: 'Saving',
                description: 'CVAT is saving your annotations, please wait.',
                icon: <SaveTwoTone twoToneColor="#50C878" />,
            });
        }
    }, [saving]);

    // Redirect when saving is complete
    useEffect(() => {
        if (!saving && redirect) {
            let url;
            console.log(firstJob);
            if (firstJob) {
                url = `/tasks/${firstJob.taskId}/jobs/${firstJob.id}`;
            } else {
                url = '/reviews'; // redirect to home if firstJob is null
            }
            history.push(url);
            setRedirect(false); // Reset the flag after redirect
        }
    }, [saving, redirect]);


    useEffect(() => {
        if (jobs && jobs.length > 0) {
            console.log("All job IDs:", jobs.map((job: Job) => job.id));
            const afterFirstFilter = jobs.filter((job: Job) => job.id !== Number(jid));
            console.log("After first filter (excluding jid):", afterFirstFilter.map((job: Job) => job.id));
            const userFilterJobs = jobs.filter((job: Job) => job.id !== Number(jid))
            let newFirstJob;
            console.log(userFilterJobs);
            if (userFilterJobs.length > 1) {
                // Select a random job when there is more than one job
                const randomIndex = Math.floor(Math.random() * userFilterJobs.length);
                newFirstJob = userFilterJobs[randomIndex];
            } else if (userFilterJobs.length === 1) {
                // Select the only job when there is one job
                newFirstJob = userFilterJobs[0];
            } else {
                newFirstJob = null
            }

            setFirstJob(newFirstJob);

            // const currentJob = jobs.find((job: Job) => job.id === Number(jid));
            // if (currentJob) {
            //TODO: IMPLEMENT REVIEWEE SETTING HERE
            // currentJob.save();
            // }
        }
    }, [jobs]);

    const includesToolsBlockerButton =
        [ActiveControl.OPENCV_TOOLS, ActiveControl.AI_TOOLS].includes(activeControl) && toolsBlockerState.buttonVisible;

    const shouldEnableToolsBlockerOnClick = [ActiveControl.OPENCV_TOOLS].includes(activeControl);

    const approve = async () => {
        // Fetch new jobs
        await dispatch(getJobsAsync(reviewerFilteredQuery));

        // Save current annotation
        onSaveAnnotation();

        const currentJob = jobs.find((job: Job) => job.id === Number(jid));

        if (currentJob && currentJob.doubleReviewCycle) {
            console.log(currentJob);
            //TODO: IMPLEMENT REVIEWEE SETTING HERE
            currentJob.stage = JobStage.VALIDATION
            currentJob.save();
        }

        if (currentJob && !currentJob.doubleReviewCycle) {
            currentJob.stage = JobStage.ACCEPTANCE
            currentJob.save();
        }

        if (!saving) {
            // Set the redirect flag
            setRedirect(true);
        }
    };


    const deny = async () => {
        // Fetch new jobs
        await dispatch(getJobsAsync(reviewerFilteredQuery));

        // Save current annotation
        onSaveAnnotation();

        const currentJob = jobs.find((job: Job) => job.id === Number(jid));
        if (currentJob) {
            //TODO: IMPLEMENT REVIEWEE SETTING HERE
            currentJob.stage = JobStage.ANNOTATION
            currentJob.state = JobState.NEW
            currentJob.save();
        }

        if (!saving) {
            // Set the redirect flag
            setRedirect(true);
        }
    };

    return (
        <>
            <Col className='cvat-annotation-header-left-group'>
                <Dropdown overlay={<AnnotationMenuContainer />}>
                    <Button type='link' className='cvat-annotation-header-menu-button cvat-annotation-header-button'>
                        <Icon component={MainMenuIcon} />
                        Menu
                    </Button>
                </Dropdown>
                <CVATTooltip overlay={`Undo: ${undoAction} ${undoShortcut}`}>
                    <Button
                        style={{ pointerEvents: undoAction ? 'initial' : 'none', opacity: undoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-undo-button cvat-annotation-header-button'
                        onClick={onUndoClick}
                    >
                        <Icon component={UndoIcon} />
                        <span>Undo</span>
                    </Button>
                </CVATTooltip>
                <CVATTooltip overlay={`Redo: ${redoAction} ${redoShortcut}`}>
                    <Button
                        style={{ pointerEvents: redoAction ? 'initial' : 'none', opacity: redoAction ? 1 : 0.5 }}
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={onRedoClick}
                    >
                        <Icon component={RedoIcon} />
                        Redo
                    </Button>

                </CVATTooltip>
                {includesDoneButton ? (
                    <CVATTooltip overlay={`Press "${drawShortcut}" to finish`}>
                        <Button
                            type='link'
                            className='cvat-annotation-header-done-button cvat-annotation-header-button'
                            onClick={onFinishDraw}
                        >
                            <CheckCircleOutlined />
                            Done
                        </Button>
                    </CVATTooltip>
                ) : null}
                {includesToolsBlockerButton ? (
                    <CVATTooltip overlay={`Press "${switchToolsBlockerShortcut}" to postpone running the algorithm `}>
                        <Button
                            type='link'
                            className={`cvat-annotation-header-block-tool-button cvat-annotation-header-button ${toolsBlockerState.algorithmsLocked ? 'cvat-button-active' : ''
                                }`}
                            onClick={shouldEnableToolsBlockerOnClick ? onSwitchToolsBlockerState : undefined}
                        >
                            <StopOutlined />
                            Block
                        </Button>
                    </CVATTooltip>
                ) : null}
            </Col>
            <CVATTooltip overlay={`Save current changes ${saveShortcut}`}>
                <Button
                    onClick={saving ? undefined : onSaveAnnotation}
                    type='link'
                    className={
                        saving
                            ? 'cvat-annotation-header-save-button cvat-annotation-disabled-header-button'
                            : 'cvat-annotation-header-save-button cvat-annotation-header-button'
                    }
                >
                    <Icon component={SaveIcon} />
                    {saving ? 'Saving...' : 'Save'}
                </Button>
            </CVATTooltip>
            <Col className='cvat-annotation-header-left-group-reviewer-button'>
                <CVATTooltip>
                    <Button
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={approve}
                    >
                        <CheckCircleTwoTone />
                        Approve
                    </Button>
                </CVATTooltip>
                <CVATTooltip>
                    <Button
                        type='link'
                        className='cvat-annotation-header-redo-button cvat-annotation-header-button'
                        onClick={deny}
                    >
                        <CloseCircleTwoTone twoToneColor="#eb2f96" />
                        Deny
                    </Button>
                </CVATTooltip>
            </Col >
            {/* //TODO fix image collision by checking for assignee. */}
        </>
    );
}

export default React.memo(LeftGroupReviewer);
