// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import moment from 'moment';
import { Row, Col } from 'antd/lib/grid';
import Switch from 'antd/lib/switch';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';

import { getCore, Project } from 'cvat-core-wrapper';
import LabelsEditor from 'components/labels-editor/labels-editor';
import BugTrackerEditor from 'components/task-page/bug-tracker-editor';
import UserSelector from 'components/task-page/user-selector';
import AnnotatorsSelector from 'components/task-page/annotators-selector';
import MdGuideControl from 'components/md-guide/md-guide-control';

const core = getCore();

interface DetailsComponentProps {
    project: Project;
    onUpdateProject: (project: Project) => void;
}

export default function DetailsComponent(props: DetailsComponentProps): JSX.Element {
    const { project, onUpdateProject } = props;
    const [projectName, setProjectName] = useState(project.name);
    console.log(project);

    return (
        <div data-cvat-project-id={project.id} className='cvat-project-details'>
            <Row>
                <Col>
                    <Title
                        level={4}
                        editable={{
                            onChange: (value: string): void => {
                                setProjectName(value);
                                project.name = value;
                                project.projectLabel = project.projectLabel;
                                onUpdateProject(project);
                            },
                        }}
                        className='cvat-text-color cvat-project-name'
                    >
                        {projectName}
                    </Title>
                </Col>
            </Row>

            <Row justify='space-between' className='cvat-project-description'>
                <Col>
                    <Text type='secondary'>
                        {`Project label ${project.projectLabel}`}<br />
                    </Text>
                    <Text type='secondary'>
                        {`Project code ${project.projectCode}`}<br />
                    </Text>
                    <Text type='secondary'>
                        {`Project type ${project.projectType}`}<br />
                    </Text>
                </Col>
            </Row>

            <Row justify='space-between' className='cvat-project-description'>
                <Col>
                    <Text type='secondary'>
                        {`Project #${project.id} created`}
                        {project.owner ? ` by ${project.owner.username}` : null}
                        {` on ${moment(project.createdDate).format('MMMM Do YYYY')}`}
                    </Text>

                    <MdGuideControl instanceType='project' id={project.id} />
                    <BugTrackerEditor
                        instance={project}
                        onChange={(bugTracker): void => {
                            project.bugTracker = bugTracker;
                            onUpdateProject(project);
                        }}
                    />
                </Col>
                {/* <Col> */}
                {/*     <Text type='secondary'>Assigned to</Text> */}
                {/*     <UserSelector */}
                {/*         value={project.assignee} */}
                {/*         onSelect={(user) => { */}
                {/*             project.assignee = user; */}
                {/*             onUpdateProject(project); */}
                {/*         }} */}
                {/*     /> */}
                {/* </Col> */}

                <Col span={10}>

                    <Row>
                        <Col span={24}>
                            <Text type='secondary'>Double Review Cycle:  </Text>
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                unCheckedChildren={<CloseOutlined />}
                                defaultChecked={false}
                                checked={project.doubleReviewCycle}
                                onChange={(checked) => {
                                    project.doubleReviewCycle = checked;
                                    onUpdateProject(project);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Text type='secondary'>Annotators:</Text>
                        </Col>
                        <Col span={24}>
                            <AnnotatorsSelector
                                requiredRole="User"
                                value={project.annotators}
                                onChange={(annotators) => {
                                    project.annotators = annotators || [];
                                    onUpdateProject(project);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Text type='secondary'>Reviewers:</Text>
                        </Col>
                        <Col span={24}>
                            <AnnotatorsSelector
                                requiredRole="Reviewer"
                                value={project.reviewers}
                                onChange={(reviewers) => {
                                    project.reviewers = reviewers || [];
                                    onUpdateProject(project);
                                }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Text type='secondary'>Secondary Reviewers:</Text>
                        </Col>
                        <Col span={24}>
                            <AnnotatorsSelector
                                requiredRole="Reviewer"
                                value={project.reviewers2}
                                onChange={(reviewers2) => {
                                    project.reviewers2 = reviewers2;
                                    onUpdateProject(project);
                                }}
                                disabled={!project.doubleReviewCycle}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Text type='secondary'>Supervisors:</Text>
                        </Col>
                        <Col span={24}>
                            <AnnotatorsSelector
                                requiredRole="Supervisor"
                                value={project.supervisors}
                                onChange={(supervisors) => {
                                    project.supervisors = supervisors;
                                    console.log(supervisors);
                                    onUpdateProject(project);
                                    console.log(project);
                                }}
                            />
                        </Col>
                    </Row>

                </Col>
            </Row>
            <LabelsEditor
                labels={project.labels.map((label: any): string => label.toJSON())}
                onSubmit={(labels: any[]): void => {
                    project.labels = labels.map((labelData): any => new core.classes.Label(labelData));
                    onUpdateProject(project);
                }}
            />
        </div>
    );
}
