// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { Table } from 'antd';
import moment from 'moment';

import { CombinedState, Project } from 'reducers';
import { getJobsAsync } from 'actions/jobs-actions';

export default function DashboardListComponent(): JSX.Element {
    const dispatch = useDispatch();
    const tasks = useSelector((state: CombinedState) => state.tasks.current);
    const tasksCount = useSelector((state: CombinedState) => state.tasks.count);
    const query = useSelector((state: CombinedState) => state.jobs.query);
    const [loadingJobs, setLoadingJobs] = useState(true);
    const projects = useSelector((state: CombinedState) => state.projects.current);

    console.log(tasks);

    useEffect(() => {
        const fetchJobs = async () => {
            setLoadingJobs(true);
            await dispatch(getJobsAsync({ ...query }));
            setLoadingJobs(false);
        };

        fetchJobs();
    }, []);

    const dimensions = {
        md: 22,
        lg: 18,
        xl: 16,
        xxl: 16,
    };


    const getProjectDetailsById = (projectId: number) => {
        const project = projects.find(p => p.id === projectId);
        return project || null;
    };

    const validTasks = tasks.filter(task => projects.some(p => p.id === task.projectId));

    const columns = [
        {
            title: 'Project Name',
            key: 'projectName',
            render: (task) => {
                const project = getProjectDetailsById(task.projectId);
                return project ? project.name : 'N/A';
            }
        },
        {
            title: 'Project ID',
            dataIndex: 'projectId', // assuming this is the field name for project id in task
            key: 'projectId',
        },
        {
            title: 'Task Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Total Images',
            key: 'totalJobs',
            render: (task) => task.progress.totalJobs,
            sorter: (a, b) => a.progress.totalJobs - b.progress.totalJobs,
        },
        {
            title: 'Yet to be Reviewed',
            key: 'reviewJobs',
            render: (task) => task.progress.reviewJobs,
            sorter: (a, b) => a.progress.reviewJobs - b.progress.reviewJobs,
        },
        {
            title: 'Completed Images',
            key: 'completedJobs',
            render: (task) => task.progress.completedJobs,
            sorter: (a, b) => a.progress.completedJobs - b.progress.completedJobs,
        },
        {
            title: 'Project Label',
            key: 'projectLabel',
            render: (task) => {
                const project = getProjectDetailsById(task.projectId);
                return project ? project.label : 'N/A'; // adjust according to the structure of your project object
            }
        },
        {
            title: 'Created Date', // Assuming you want to display the creation date for tasks
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (date: string) => moment(date).format('DD/MM/YY'),
            sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
        },
    ];

    return (
        <>
            <Row justify='center' align='middle' className='cvat-project-list-content'>
                <Col {...dimensions}>
                    <Table
                        loading={loadingJobs}
                        dataSource={validTasks}
                        columns={columns}
                        rowKey="id"
                        pagination={{
                            pageSize: 12,
                            total: tasksCount,
                            showSizeChanger: false,
                            showQuickJumper: true,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}
